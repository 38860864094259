import dynamic from 'next/dynamic'
import HFilmList from '@c/h-film-listall'
import HFilmListShort from '@c/h-film-list-short'
import Footer from '@c/footer'
import Banner from '@c/banner'
import Responsive from '@c/responsive'
import { getPageData } from '@/api'
import { FILM_URL } from '@/constant'
import { useEffect, useMemo } from 'react'
import { useScrollSessionStore } from '@/utils/hooks'
import EventBus from '@/utils/emitter'
const GuideDownload = dynamic(() => import('@c/guide-download'))
const TvLive = dynamic(() => import('@c/tv-live'))

export default function Home(props) {
  const { headers, cards } = props

  const pageScroll = 'homeScroll'
  useScrollSessionStore(pageScroll)

  useEffect(() => {
    const top = sessionStorage.getItem(pageScroll) || 0
    window.scroll(0, top)
  }, [])

  useEffect(() => {
    getPageData('首页', {
      headers: {
        token: localStorage.getItem('token')
      }
    }).then(res => {
      EventBus.emit('firstLevelPage', false, res?.data?.groups || [])
      EventBus.emit('updateBanner', false, res?.data?.banners || [])
    })
    return () => {
      sessionStorage.setItem('lastPage', true)
    }
  }, [])

  const banners = useMemo(() => (headers ? headers : []).filter(item => item.title), [headers])

  return (
    <>
      <Banner data={banners} needTab={false} />

      {/* 移动端 */}
      <Responsive>
        {cards.map((item, index) => {
          const TvNode = ({ data }) => {
            return (
              <>
                <TvLive data={data} />
              </>
            )
          }
          if (item.name == '电视直播') {
            return <TvNode data={item.cards} key={index} />
          } else if (item.name == '电影1') {
            return <HFilmListShort data={item} key={index} source={FILM_URL[item.name]} />
          } else {
            return (
              <HFilmList
                lessItems
                data={item}
                key={index}
                source={FILM_URL[item.name]}
                isHome
                top={item.ban ? 0 : 40}
              />
            )
          }
        })}
        <Footer />
      </Responsive>
    </>
  )
}

export async function getServerSideProps() {
  const res = await getPageData('首页', {})

  let props = {
    layoutProps: {
      noSomeMetaInfo: false,
      url: '/',
      title: '华视界 - 海外华人免费中文电视直播和影视大全',
      desc: '华视界是一款海外华人电视直播和影视大全，免费观看中文电视节目和影视内容。在这里，您可以畅享中文直播内容及海量影视资源，涵盖香港、台湾、新马、大陆频道直播，以及电视剧、电影、综艺、动漫节目点播等，尽情享受视听盛宴。'
    },
    cards: [],
    headers: []
  }

  if (!res.code) {
    const cards = res?.data?.groups?.map(item => {
      return {
        ...item,
        ban: item.name === '现在热播' || item.name === '热播影视',
        name: item.name
      }
    })

    props.headers = res?.data?.banners || []
    props.cards = cards || []
  }

  return {
    props
  }
}
